export const PRODUCT_CATEGORY_FIELDS_FRAGMENT = /* GraphQL */ `
  fragment ProductCategoryFields on ProductCategory {
    name
  }
`;

export const PRODUCT_ATTRIBUTE_FIELDS_FRAGMENT = /* GraphQL */ `
  fragment ProductAttributeFields on ProductAttribute {
    id
    label
    attributeId
    name
    options {
      name
      slug
      taxonomy
    }
    position
    variation
    visible
  }
`;
export const PRODUCT_PRICING_FIELDS_FRAGMENT = /* GraphQL */ `
  fragment ProductPricingFields on ProductWithPricing {
    price
    salePrice
    regularPrice
  }
`;

export const MEDIA_ITEM_FIELDS_FRAGMENT = /* GraphQL */ `
  fragment MediaItemFields on MediaItem {
    mediaType
    mimeType
    slug
    sizes
    srcSet
    sourceUrl
    uri
    mediaItemUrl
    altText
    title
  }
`;

export const PRODUCT_VARIATION_FIELDS_FRAGMENT = /* GraphQL */ `
  fragment ProductVariationFields on ProductWithVariations {
    variations {
      nodes {
        id
        databaseId
        name
        price
        salePrice
        regularPrice
        purchasable
        stockStatus
        attributes {
          nodes {
            id
            name
            value
          }
        }
      }
    }
  }
`;

export const PRODUCT_REVIEWS_FRAGMENT = /* GraphQL */ `
  fragment ProductReviews on Product {
    reviews {
      edges {
        rating
        node {
          id
          author {
            node {
              name
              avatar {
                default
                url
                size
                width
              }
            }
          }
          agent
          content
          date
          dateGmt
          status
        }
      }
    }
  }
`;

export const PRODUCT_FEATURED_IMAGE_FRAGMENT = /* GraphQL */ `
  fragment ProductFeaturedImage on Product {
    featuredImage {
      node {
        altText
        uri
        title
        sizes
        mediaType
        mimeType
        sourceUrl
      }
    }
  }
`;

export const PRODUCT_CONTENT_SLICE_FRAGMENT = /* GraphQL */ `
  fragment ProductContentSlice on Product {
    id
    databaseId
    name
    slug
    type
    image {
      id
      sourceUrl(size: WOOCOMMERCE_THUMBNAIL)
      altText
    }
    ...ProductFeaturedImage
    ... on SimpleProduct {
      price
      regularPrice
      soldIndividually
    }
    ... on VariableProduct {
      price
      regularPrice
      soldIndividually
    }
  }
  ${PRODUCT_FEATURED_IMAGE_FRAGMENT}
`;

export const SEO_FRAGMENT = /* GraphQL */ `
  fragment SEO on RankMathSeo {
    breadcrumbs {
      text
      url
    }
    canonicalUrl
    description
    openGraph {
      description
      updatedTime
      siteName
      title
      url
      type
      twitterMeta {
        description
        image
        title
      }
    }
    robots
    title
  }
`;

export const PRODUCT_VARIATION_CONTENT_SLICE_FRAGMENT = /* GraphQL */ `
  fragment ProductVariationContentSlice on ProductVariation {
    id
    databaseId
    name
    slug
    image {
      id
      sourceUrl(size: WOOCOMMERCE_THUMBNAIL)
      altText
    }
    attributes {
      nodes {
        id
        label
        attributeId
        name
      }
    }
    price
    regularPrice
  }
`;

export const CART_ITEM_CONTENT_FRAGMENT = /* GraphQL */ `
  fragment CartItemContent on CartItem {
    key
    product {
      node {
        ...ProductContentSlice
      }
    }
    variation {
      node {
        name
        image {
          sourceUrl
        }
        price
        attributes {
          nodes {
            name
            value
            label
          }
        }
      }
    }
    quantity
    total
    subtotal
    subtotalTax
    extraData {
      key
      value
    }
  }
  ${PRODUCT_CONTENT_SLICE_FRAGMENT}
`;

export const CART_FIELDS_FRAGMENT = /* GraphQL */ `
  fragment CartFields on Cart {
    contents(first: 100) {
      itemCount
      nodes {
        ...CartItemContent
      }
    }
    appliedCoupons {
      code
      discountAmount
      discountTax
    }
    fees {
      amount
      id
      name
      taxable
      taxClass
      total
    }
    needsShippingAddress
    availableShippingMethods {
      packageName
      packageDetails
      packageTotal
      packageContents {
        ...CartItemContent
      }
      supportsShippingCalculator
      rates {
        id
        instanceId
        methodId
        label
        cost
        extraInfo
      }
    }
    subtotal
    subtotalTax
    shippingTax
    shippingTotal
    total
    totalTax
    feeTax
    feeTotal
    discountTax
    discountTotal
  }
  ${CART_ITEM_CONTENT_FRAGMENT}
`;

export const ADDRESS_FIELDS_FRAGMENT = /* GraphQL */ `
  fragment AddressFields on CustomerAddress {
    firstName
    lastName
    company
    address1
    address2
    city
    state
    country
    postcode
    phone
  }
`;

export const ADDITIONAL_ADDRESS_FIELDS_FRAGMENT = /* GraphQL */ `
  fragment AdditionalAddressFields on CustomerAdditionalAddress {
    id
    addressTitle
    address {
      ...AddressFields
    }
  }
`;

export const LINE_ITEM_FIELDS_FRAGMENT = /* GraphQL */ `
  fragment LineItemFields on LineItem {
    databaseId
    product {
      node {
        ...ProductContentSlice
      }
    }
    variation {
      node {
        name
        image {
          sourceUrl
        }
        price
        attributes {
          nodes {
            name
            value
            label
          }
        }
      }
    }
    orderId
    quantity
    subtotal
    total
  }
  ${PRODUCT_CONTENT_SLICE_FRAGMENT}
`;

export const ORDER_FIELDS_FRAGMENT = /* GraphQL */ `
  fragment OrderFields on Order {
    id
    databaseId
    orderNumber
    status
    currency
    needsProcessing
    subtotal(format: RAW)
    paymentMethodTitle
    total(format: RAW)
    date
    dateCompleted
    datePaid
    shippingTotal(format: RAW)
    discountTotal(format: RAW)
    shipping {
      ...AddressFields
    }
    feeLines {
      nodes {
        id
        total
        name
      }
    }
    lineItems(first: 100) {
      nodes {
        ...LineItemFields
      }
    }
    paymentMethod
    paymentMethodTitle
    thankyouInstructions
  }
  ${ADDRESS_FIELDS_FRAGMENT}
  ${LINE_ITEM_FIELDS_FRAGMENT}
`;

export const CUSTOMER_FIELDS_FRAGMENT = /* GraphQL */ `
  fragment CustomerFields on Customer {
    id
    databaseId
    firstName
    lastName
    displayName
    billing {
      ...AddressFields
    }
    shipping {
      ...AddressFields
    }
    orders(first: 100) {
      nodes {
        ...OrderFields
      }
    }
  }
  ${ADDRESS_FIELDS_FRAGMENT}
  ${ORDER_FIELDS_FRAGMENT}
`;

export const BLOG_CATEGORY_CONTENT_FRAGMENT = /* GraphQL */ `
  fragment BlogCategoryContent on Category {
    id
    uri
    name
  }
`;

export const BLOG_POST_CONTENT_FRAGMENT = /* GraphQL */ `
  fragment BlogPostContent on Post {
    date
    dateGmt
    id
    modified
    modifiedGmt
    slug
    status
    title
    uri
    excerpt
    featuredImage {
      node {
        altText
        mediaItemUrl
        mediaType
        mimeType
        sizes
        sourceUrl(size: WOOCOMMERCE_THUMBNAIL)
        srcSet
        title(format: RAW)
      }
    }
  }
`;

export const PAGE_INFO_CONTENT_FRAGMENT = /* GraphQL */ `
  fragment PageInfoContent on PageInfo {
    endCursor
    hasNextPage
    hasPreviousPage
    startCursor
  }
`;

export const PRODUCT_RELATED_PRODUCTS_FRAGMENT = /* GraphQL */ `
  fragment RelatedProducts on Product {
    related(first: 10) {
      edges {
        node {
          id
          name
          slug
          type
          image {
            ...MediaItemFields
          }
          ... on VariableProduct {
            id
            name
            price
            salePrice
            regularPrice
          }
          ... on SimpleProduct {
            id
            name
            price
            regularPrice
            salePrice
          }
          ... on GroupProduct {
            id
            name
            price
            regularPrice
            salePrice
          }
          ... on ExternalProduct {
            id
            name
            price
            regularPrice
            salePrice
          }
          ... on SimpleProductVariation {
            id
            name
            regularPrice
            salePrice
            price
          }
        }
      }
    }
  }
`;

export const PRODUCT_FAQ_FIELDS_FRAGMENT = /* GraphQL */ `
  fragment ProductFAQFields on Product {
    productFaqFields {
      faqItem {
        question
        answer
      }
    }
  }
`;

export const PRODUCT_FIELDS_FRAGMENT = /* GraphQL */ `
  fragment ProductFields on Product {
    id
    databaseId
    name
    purchasable
    slug
    type
    averageRating
    reviewCount
    modified
    modifiedGmt
    uri
    brand: allPaBrand(first: 1) {
      nodes {
        name
        slug
      }
    }
    productForms: allPaProductForm(first: 1) {
      nodes {
        name
      }
    }
    shippingClasses {
      nodes {
        name
        description
      }
    }
    attributes {
      nodes {
        ...ProductAttributeFields
      }
    }
    productCategories {
      nodes {
        name
        uri
      }
    }
    ...ProductPricingFields
  }
  ${PRODUCT_ATTRIBUTE_FIELDS_FRAGMENT}
  ${PRODUCT_PRICING_FIELDS_FRAGMENT}
`;

export const CUSTOMER_ADDRESS_FRAGMENT = /* GraphQL */ `
  fragment AddressFields on CustomerAddress {
    firstName
    lastName
    company
    address1
    address2
    city
    state
    country
    postcode
    phone
  }
`;
export const CUSTOMER_FRAGMENT = /* GraphQL */ `
  fragment CustomerFields on Customer {
    id
    email
    databaseId
    firstName
    lastName
    displayName
    billing {
      ...AddressFields
    }
    shipping {
      ...AddressFields
    }
    additionalAddresses {
      ...AdditionalAddressFields
    }
  }
  ${CUSTOMER_ADDRESS_FRAGMENT}
  ${ADDITIONAL_ADDRESS_FIELDS_FRAGMENT}
`;
