import {
  ADD_TO_CART_MUTATION,
  APPLY_COUPON_MUTATION,
  CHECKOUT_MUTATION,
  CREATE_TICKET_MUTATION,
  LOGOUT_MUTATION,
  REFRESH_TOKEN_MUTATION,
  REGISTER_CUSTOMER_MUTATION,
  REMOVE_CART_PRODUCT_MUTATION,
  REPLY_TICKET_MUTATION,
  SAVE_ADDITIONAL_ADDRESS_MUTATION,
  SEND_PASSWORD_RESET_MUTATION,
  SET_PAYMENT_METHOD,
  SET_SHIPPING_METHOD,
  SIGN_IN_MUTATION,
  UPDATE_CART_QUANTITY_MUTATION,
  UPDATE_CUSTOMER_ADDRESS,
  WRITE_REVIEW_MUTATION
} from './mutations';
import {
  GET_ACCOUNT_ORDERS,
  GET_ACCOUNT_ORDER_IDS,
  GET_ACCOUNT_TICKET,
  GET_ACCOUNT_TICKETS,
  GET_BLOG_CATEGORIES_QUERY,
  GET_BLOG_CATEGORY_QUERY,
  GET_CART_FIELDS_QUERY,
  GET_CART_QUERY,
  GET_CHECKOUT_FIELDS_QUERY,
  GET_COUNTRIES_QUERY,
  GET_COUNTRY_STATES_QUERY,
  GET_CUSTOMER_QUERY,
  GET_MENU_QUERY,
  GET_PAGE,
  GET_PAGE_HEROES_QUERY,
  GET_POSTS_QUERY,
  GET_POST_QUERY,
  GET_PRODUCTS_QUERY,
  GET_PRODUCT_ATTRIBUTE_FILTERS_QUERY,
  GET_PRODUCT_CATEGORIES_QUERY,
  GET_PRODUCT_CATEGORY_QUERY,
  GET_PRODUCT_QUERY,
  GET_ALL_BRANDS_QUERY,
  GET_URI_SEO,
  GET_BRAND_QUERY
} from './queries';
import { Menu } from './types';

const GRAPHQL_URL = process.env.NEXT_PUBLIC_GRAPHQL_URL ?? 'http://localhost:8080/graphql';
type GraphQLVariables = { [key: string]: string | [] | {} | GraphQLVariables };

//TODO: ACTIVE WP CORs PLUGIN
async function fetchApiInstance(
  url: string,
  options: (RequestInit & { _retry?: boolean }) | undefined
) {
  const headers = new Headers({
    'Content-Type': 'application/json'
  });

  // Setting up the headers
  if (typeof localStorage !== 'undefined') {
    const wooSessionToken = localStorage.getItem('woocommerce-session');
    if (wooSessionToken) headers.set('woocommerce-session', `Session ${wooSessionToken}`);

    const authToken = localStorage.getItem('authToken');
    if (authToken) headers.set('Authorization', `Bearer ${authToken}`);
  }

  // Merge additional headers
  if (options?.headers) {
    for (const [key, value] of Object.entries(options.headers)) {
      headers.set(key, value as string);
    }
  }

  // Execute the fetch
  try {
    const response = await fetch(url, { ...options, headers });

    // Process response headers
    if (typeof localStorage !== 'undefined') {
      const wooSessionToken = response.headers.get('woocommerce-session');
      if (wooSessionToken) {
        localStorage.setItem('woocommerce-session', wooSessionToken);
      }
    }

    // Check for errors in the response body and handle refresh token process
    const data = await response.clone().json();
    if (data.errors && !options?._retry) {
      const targetErrors = [
        'The iss do not match with this server',
        'invalid-secret-key | Expired token',
        'invalid-secret-key | Signature verification failed',
        'Expired token',
        'Wrong number of segments'
      ];

      for (const { debugMessage, message } of data.errors) {
        if (targetErrors.includes(message) || targetErrors.includes(debugMessage)) {
          console.log('Refreshing token..');
          localStorage.removeItem('authToken');
          await refreshAuthToken(); // Assume refreshAuthToken is a function that refreshes the auth token
          return fetchApiInstance(url, { ...options, _retry: true }); // Retry fetch with the new token
        }
      }
    }

    return response;
  } catch (error) {
    // Handle fetch error
    console.error('Fetch failed', error);
    throw error;
  }
}

export const refreshAuthToken = async () => {
  const refreshToken = localStorage.getItem('refreshToken');

  const response = await fetchApiInstance(GRAPHQL_URL, {
    method: 'POST',
    body: JSON.stringify({
      query: REFRESH_TOKEN_MUTATION,
      variables: {
        refreshToken
      }
    })
  });

  if (!response.ok) {
    throw new Error(`HTTP error! Status: ${response.status}`);
  }

  const data = await response.json();
  localStorage.setItem('authToken', data.data.refreshJwtAuthToken.authToken);
};

export const executeQuery = async (query: string, variables: GraphQLVariables) => {
  const response = await fetchApiInstance(GRAPHQL_URL, {
    method: 'POST',
    body: JSON.stringify({
      query,
      variables
    })
  });

  if (!response.ok) {
    throw new Error(`HTTP error! Status: ${response.status}`);
  }

  const data = await response.json();

  // Validate that the response has the expected structure
  if (data.errors) {
    throw new Error(
      `Error fetching menu: ${data.errors.map((e: { message: any }) => e.message).join(', ')}`
    );
  }

  return data.data;
};

export const getMenu = async (id: string, idType = 'NAME'): Promise<Menu> => {
  const response = await fetchApiInstance(GRAPHQL_URL, {
    method: 'POST',
    body: JSON.stringify({
      query: GET_MENU_QUERY,
      variables: {
        id,
        idType
      }
    })
  });

  if (!response.ok) {
    throw new Error(`HTTP error! Status: ${response.status}`);
  }

  const data = await response.json();

  // Validate that the response has the expected structure
  if (data.errors) {
    throw new Error(`Error fetching menu: ${data.errors.map((e: Error) => e.message).join(', ')}`);
  }

  return data.data.menu;
};

export const getProduct = async (variables: GraphQLVariables) => {
  const response = await fetchApiInstance(GRAPHQL_URL, {
    method: 'POST',
    body: JSON.stringify({
      query: GET_PRODUCT_QUERY,
      variables
    })
  });

  if (!response.ok) {
    throw new Error(`HTTP error! Status: ${response.status}`);
  }

  const data = await response.json();

  if (data.errors) {
    throw new Error(
      `Error fetching product: ${data.errors.map((e: Error) => e.message).join(', ')}`
    );
  }

  return data.data.product;
};

export const addToCart = async (productId: number, quantity = 1) => {
  const response = await fetchApiInstance(GRAPHQL_URL, {
    method: 'POST',
    cache: 'no-store',
    body: JSON.stringify({
      query: ADD_TO_CART_MUTATION,
      variables: {
        productId,
        quantity
      }
    })
  });

  if (!response.ok) {
    throw new Error(`HTTP error! Status: ${response.status}`);
  }

  const data = await response.json();
  return data.data.addToCart;
};

export const getCart = async () => {
  const response = await fetchApiInstance(GRAPHQL_URL, {
    method: 'POST',
    body: JSON.stringify({
      query: GET_CART_QUERY
    }),
    cache: 'no-store'
  });

  if (!response.ok) {
    throw new Error(`HTTP error! Status: ${response.status}`);
  }

  const data = await response.json();

  if (data.errors) {
    //throw new Error(data.errors[0].message);
    throw new Error(data.errors[0].message);
  }

  return data.data.cart;
};

export const getCheckout = async () => {
  const response = await fetchApiInstance(GRAPHQL_URL, {
    method: 'POST',
    body: JSON.stringify({
      query: GET_CHECKOUT_FIELDS_QUERY
    }),
    cache: 'no-store'
  });

  if (!response.ok) {
    throw new Error(`HTTP error! Status: ${response.status}`);
  }

  const data = await response.json();
  return data.data;
};

export const getCountries = async () => {
  const response = await fetchApiInstance(GRAPHQL_URL, {
    method: 'POST',
    body: JSON.stringify({
      query: GET_COUNTRIES_QUERY
    }),
    cache: 'force-cache'
  });

  if (!response.ok) {
    throw new Error(`HTTP error! Status: ${response.status}`);
  }

  const data = await response.json();
  return data.data.countries;
};

export const getCountryStates = async (country: string) => {
  const response = await fetchApiInstance(GRAPHQL_URL, {
    method: 'POST',
    body: JSON.stringify({
      query: GET_COUNTRY_STATES_QUERY,
      variables: {
        country
      }
    })
  });

  if (!response.ok) {
    throw new Error(`HTTP error! Status: ${response.status}`);
  }

  const data = await response.json();
  return data.data.countryStates;
};

export const getCartPageFields = async () => {
  const response = await fetchApiInstance(GRAPHQL_URL, {
    method: 'POST',
    body: JSON.stringify({
      query: GET_CART_FIELDS_QUERY
    }),
    cache: 'no-store'
  });

  if (!response.ok) {
    throw new Error(`HTTP error! Status: ${response.status}`);
  }

  const data = await response.json();
  return data.data;
};

export const signIn = async (variables: GraphQLVariables) => {
  const response = await fetchApiInstance(GRAPHQL_URL, {
    method: 'POST',
    cache: 'no-store',
    body: JSON.stringify({
      query: SIGN_IN_MUTATION,
      variables
    })
  });

  if (!response.ok) {
    throw new Error(`HTTP error! Status: ${response.status}`);
  }

  const data = await response.json();

  if (data.errors) {
    //throw new Error(data.errors[0].message);
    throw new Error('Signing failed. Please check your credentials and try again.');
  }

  return data.data.login;
};

export const registerCustomer = async (variables: GraphQLVariables) => {
  const response = await fetchApiInstance(GRAPHQL_URL, {
    method: 'POST',
    cache: 'no-store',
    body: JSON.stringify({
      query: REGISTER_CUSTOMER_MUTATION,
      variables
    })
  });

  if (!response.ok) {
    throw new Error(`HTTP error! Status: ${response.status}`);
  }

  const data = await response.json();

  if (data.errors) {
    //throw new Error(data.errors[0].message);
    throw new Error(data.errors[0].message);
  }

  return data.data.registerCustomer;
};

export const updateCartProductQuantity = async (items: [{ key: string; quantity: number }]) => {
  const response = await fetchApiInstance(GRAPHQL_URL, {
    method: 'POST',
    cache: 'no-store',
    body: JSON.stringify({
      query: UPDATE_CART_QUANTITY_MUTATION,
      variables: {
        items
      }
    })
  });

  if (!response.ok) {
    throw new Error(`HTTP error! Status: ${response.status}`);
  }

  const data = await response.json();
  return data.data.updateItemQuantities;
};

export const removeCartProduct = async (cartKey: string) => {
  const response = await fetchApiInstance(GRAPHQL_URL, {
    method: 'POST',
    cache: 'no-store',
    body: JSON.stringify({
      query: REMOVE_CART_PRODUCT_MUTATION,
      variables: {
        cartKey
      }
    })
  });

  if (!response.ok) {
    throw new Error(`HTTP error! Status: ${response.status}`);
  }

  const data = await response.json();
  return data.data.removeCartProduct.cart;
};

export const getCategories = async (variables: GraphQLVariables) => {
  const response = await fetchApiInstance(GRAPHQL_URL, {
    method: 'POST',
    body: JSON.stringify({
      query: GET_PRODUCT_CATEGORIES_QUERY,
      variables
    })
  });

  if (!response.ok) {
    throw new Error(`HTTP error! Status: ${response.status}`);
  }

  const data = await response.json();
  return data.data.productCategories.nodes;
};

export const getCategory = async (variables: GraphQLVariables) => {
  const response = await fetchApiInstance(GRAPHQL_URL, {
    method: 'POST',
    body: JSON.stringify({
      query: GET_PRODUCT_CATEGORY_QUERY,
      variables
    })
  });

  if (!response.ok) {
    throw new Error(`HTTP error! Status: ${response.status}`);
  }

  const data = await response.json();

  if (data.errors) {
    throw new Error(data.errors[0].message);
  }

  return data.data.productCategory;
};

export const getBlogCategory = async (variables: GraphQLVariables) => {
  const response = await fetchApiInstance(GRAPHQL_URL, {
    method: 'POST',
    body: JSON.stringify({
      query: GET_BLOG_CATEGORY_QUERY,
      variables
    })
  });

  if (!response.ok) {
    throw new Error(`HTTP error! Status: ${response.status}`);
  }

  const data = await response.json();

  if (data.errors) {
    throw new Error(data.errors[0].message);
  }

  return data.data.category;
};

export const getProducts = async (variables: GraphQLVariables) => {
  const response = await fetchApiInstance(GRAPHQL_URL, {
    method: 'POST',
    body: JSON.stringify({
      query: GET_PRODUCTS_QUERY,
      variables
    })
  });

  if (!response.ok) {
    throw new Error(`HTTP error! Status: ${response.status}`);
  }

  const data = await response.json();

  if (data.errors) {
    throw new Error(
      `Error fetching Products: ${data.errors.map((e: Error) => e.message).join(', ')}`
    );
  }

  return data.data.products;
};

export const getPage = async (variables: GraphQLVariables) => {
  const response = await fetchApiInstance(GRAPHQL_URL, {
    method: 'POST',
    body: JSON.stringify({
      query: GET_PAGE,
      variables
    })
  });

  if (!response.ok) {
    throw new Error(`HTTP error! Status: ${response.status}`);
  }

  const data = await response.json();
  return data.data.pages.nodes;
};

export const getCustomer = async () => {
  const response = await fetchApiInstance(GRAPHQL_URL, {
    method: 'POST',
    body: JSON.stringify({
      query: GET_CUSTOMER_QUERY
    }),
    cache: 'no-store'
  });

  if (!response.ok) {
    throw new Error(`HTTP error! Status: ${response.status}`);
  }

  const data = await response.json();

  return data.data.customer;
};

export const getAccountTickets = async () => {
  const response = await fetchApiInstance(GRAPHQL_URL, {
    method: 'POST',
    body: JSON.stringify({
      query: GET_ACCOUNT_TICKETS
    })
  });

  if (!response.ok) {
    throw new Error(`HTTP error! Status: ${response.status}`);
  }

  const data = await response.json();

  return data.data.tickets.nodes;
};

export const getAccountTicket = async (id: number) => {
  const response = await fetchApiInstance(GRAPHQL_URL, {
    method: 'POST',
    body: JSON.stringify({
      query: GET_ACCOUNT_TICKET,
      variables: {
        id
      }
    })
  });

  if (!response.ok) {
    throw new Error(`HTTP error! Status: ${response.status}`);
  }

  const data = await response.json();

  return data.data.ticket.node;
};

export const setCustomerAddress = async (variables: GraphQLVariables) => {
  const response = await fetchApiInstance(GRAPHQL_URL, {
    method: 'POST',
    cache: 'no-store',
    body: JSON.stringify({
      query: UPDATE_CUSTOMER_ADDRESS,
      variables
    })
  });

  if (!response.ok) {
    throw new Error(`HTTP error! Status: ${response.status}`);
  }

  const data = await response.json();

  if (data.errors) {
    throw new Error(data.errors[0].message);
  }

  return data.data.updateCustomer;
};

export const setShippingMethods = async (variables: GraphQLVariables) => {
  const response = await fetchApiInstance(GRAPHQL_URL, {
    method: 'POST',
    cache: 'no-store',
    body: JSON.stringify({
      query: SET_SHIPPING_METHOD,
      variables
    })
  });

  if (!response.ok) {
    throw new Error(`HTTP error! Status: ${response.status}`);
  }

  const data = await response.json();

  if (data.errors) {
    throw new Error(data.errors[0].message);
  }

  return data.data.cart;
};

export const setPaymentMethod = async (variables: GraphQLVariables) => {
  const response = await fetchApiInstance(GRAPHQL_URL, {
    method: 'POST',
    cache: 'no-store',
    body: JSON.stringify({
      query: SET_PAYMENT_METHOD,
      variables
    })
  });

  if (!response.ok) {
    throw new Error(`HTTP error! Status: ${response.status}`);
  }

  const data = await response.json();

  if (data.errors) {
    throw new Error(data.errors[0].message);
  }

  return data.data;
};

export const checkout = async (variables: GraphQLVariables) => {
  const response = await fetchApiInstance(GRAPHQL_URL, {
    method: 'POST',
    cache: 'no-store',
    body: JSON.stringify({
      query: CHECKOUT_MUTATION,
      variables
    })
  });

  if (!response.ok) {
    throw new Error(`HTTP error! Status: ${response.status}`);
  }

  const data = await response.json();

  if (data.errors) {
    throw new Error(data.errors[0].message);
  }

  return data.data.checkout;
};

export const getPosts = async (variables: GraphQLVariables) => {
  const response = await fetchApiInstance(GRAPHQL_URL, {
    method: 'POST',
    body: JSON.stringify({
      query: GET_POSTS_QUERY,
      variables
    })
  });

  if (!response.ok) {
    throw new Error(`HTTP error! Status: ${response.status}`);
  }

  const data = await response.json();

  return data.data.posts;
};

export const getPost = async (variables: GraphQLVariables) => {
  const response = await fetchApiInstance(GRAPHQL_URL, {
    method: 'POST',
    body: JSON.stringify({
      query: GET_POST_QUERY,
      variables
    })
  });

  if (!response.ok) {
    throw new Error(`HTTP error! Status: ${response.status}`);
  }

  const data = await response.json();

  if (data.errors) {
    throw new Error(data.errors[0].message);
  }

  return data.data.post;
};

export const getBlogCategories = async (variables: GraphQLVariables) => {
  const response = await fetchApiInstance(GRAPHQL_URL, {
    method: 'POST',
    body: JSON.stringify({
      query: GET_BLOG_CATEGORIES_QUERY,
      variables
    })
  });

  if (!response.ok) {
    throw new Error(`HTTP error! Status: ${response.status}`);
  }

  const data = await response.json();

  if (data.errors) {
    throw new Error(data.errors[0].message);
  }

  return data.data.categories;
};

export const getBrands = async (variables: GraphQLVariables) => {
  const response = await fetchApiInstance(GRAPHQL_URL, {
    method: 'POST',
    body: JSON.stringify({
      query: GET_ALL_BRANDS_QUERY,
      variables
    })
  });

  if (!response.ok) {
    throw new Error(`HTTP error! Status: ${response.status}`);
  }

  const data = await response.json();
  return data.data.brands;
};

export const getBrand = async (variables: GraphQLVariables) => {
  const response = await fetchApiInstance(GRAPHQL_URL, {
    method: 'POST',
    body: JSON.stringify({
      query: GET_BRAND_QUERY,
      variables
    })
  });

  if (!response.ok) {
    throw new Error(`HTTP error! Status: ${response.status}`);
  }

  const data = await response.json();

  if (data.errors) {
    throw new Error(data.errors[0].message);
  }

  return data.data.brand;
};

export const createTicket = async (variables: GraphQLVariables) => {
  const response = await fetchApiInstance(GRAPHQL_URL, {
    method: 'POST',
    cache: 'no-store',
    body: JSON.stringify({
      query: CREATE_TICKET_MUTATION,
      variables
    })
  });

  if (!response.ok) {
    throw new Error(`HTTP error! Status: ${response.status}`);
  }

  const data = await response.json();

  if (data.errors) {
    throw new Error(data.errors[0].message);
  }

  return data.data.createTicket;
};

export const getAccountOrderIds = async (): Promise<{ databaseId: string }[]> => {
  const response = await fetchApiInstance(GRAPHQL_URL, {
    method: 'POST',
    body: JSON.stringify({
      query: GET_ACCOUNT_ORDER_IDS
    })
  });

  if (!response.ok) {
    throw new Error(`HTTP error! Status: ${response.status}`);
  }

  const data = await response.json();

  return data.data.orders.nodes;
};

export const getOrders = async () => {
  const response = await fetchApiInstance(GRAPHQL_URL, {
    method: 'POST',
    body: JSON.stringify({
      query: GET_ACCOUNT_ORDERS
    })
  });

  if (!response.ok) {
    throw new Error(`HTTP error! Status: ${response.status}`);
  }

  const data = await response.json();

  return data.data.orders.nodes;
};

export const replyTicket = async (variables: GraphQLVariables) => {
  const response = await fetchApiInstance(GRAPHQL_URL, {
    method: 'POST',
    cache: 'no-store',
    body: JSON.stringify({
      query: REPLY_TICKET_MUTATION,
      variables
    })
  });

  if (!response.ok) {
    throw new Error(`HTTP error! Status: ${response.status}`);
  }

  const data = await response.json();

  if (data.errors) {
    throw new Error(data.errors[0].message);
  }

  return data.data.replyTicket;
};

export const applyCoupon = async (variables: GraphQLVariables) => {
  const response = await fetchApiInstance(GRAPHQL_URL, {
    method: 'POST',
    cache: 'no-store',
    body: JSON.stringify({
      query: APPLY_COUPON_MUTATION,
      variables
    })
  });

  if (!response.ok) {
    throw new Error(`HTTP error! Status: ${response.status}`);
  }

  const data = await response.json();

  if (data.errors) {
    throw new Error(data.errors[0].message);
  }

  return data.data;
};

export const getProductAttributeFilters = async () => {
  const response = await fetchApiInstance(GRAPHQL_URL, {
    method: 'POST',
    body: JSON.stringify({
      query: GET_PRODUCT_ATTRIBUTE_FILTERS_QUERY
    })
  });

  if (!response.ok) {
    throw new Error(`HTTP error! Status: ${response.status}`);
  }

  const data = await response.json();

  // Validate that the response has the expected structure
  if (data.errors) {
    throw new Error(`Error fetching menu: ${data.errors.map((e: Error) => e.message).join(', ')}`);
  }

  return data.data;
};

export const writeReview = async (variables: GraphQLVariables) => {
  const response = await fetchApiInstance(GRAPHQL_URL, {
    method: 'POST',
    cache: 'no-store',
    body: JSON.stringify({
      query: WRITE_REVIEW_MUTATION,
      variables
    })
  });

  if (!response.ok) {
    throw new Error(`HTTP error! Status: ${response.status}`);
  }

  const data = await response.json();

  if (data.errors) {
    throw new Error(data.errors[0].message);
  }

  return data.data.writeReview;
};

export const getPageHeroes = async (variables: GraphQLVariables) => {
  const response = await fetchApiInstance(GRAPHQL_URL, {
    method: 'POST',
    body: JSON.stringify({
      query: GET_PAGE_HEROES_QUERY,
      variables
    })
  });

  if (!response.ok) {
    throw new Error(`HTTP error! Status: ${response.status}`);
  }

  const data = await response.json();

  return data.data.pageHeroes.nodes;
};

export const logout = async () => {
  const response = await fetchApiInstance(GRAPHQL_URL, {
    method: 'POST',
    cache: 'no-store',
    body: JSON.stringify({
      query: LOGOUT_MUTATION
    })
  });

  if (!response.ok) {
    throw new Error(`HTTP error! Status: ${response.status}`);
  }

  const data = await response.json();

  if (data.errors) {
    throw new Error(data.errors[0].message);
  }

  return data.data.logout;
};

export const saveAdditionalAddress = async (variables: GraphQLVariables) => {
  const response = await fetchApiInstance(GRAPHQL_URL, {
    method: 'POST',
    cache: 'no-store',
    body: JSON.stringify({
      query: SAVE_ADDITIONAL_ADDRESS_MUTATION,
      variables
    })
  });

  if (!response.ok) {
    throw new Error(`HTTP error! Status: ${response.status}`);
  }

  const data = await response.json();

  if (data.errors) {
    throw new Error(data.errors[0].message);
  }

  return data.data.saveAdditionalAddress.additionalAddress;
};

export const sendPasswordResetEmail = async (variables: GraphQLVariables) => {
  const response = await fetchApiInstance(GRAPHQL_URL, {
    method: 'POST',
    cache: 'no-store',
    body: JSON.stringify({
      query: SEND_PASSWORD_RESET_MUTATION,
      variables
    })
  });

  if (!response.ok) {
    throw new Error(`HTTP error! Status: ${response.status}`);
  }

  const data = await response.json();

  if (data.errors) {
    throw new Error(data.errors[0].message);
  }

  return data.data.sendPasswordResetEmail;
};

export const getURISeo = async (variables: GraphQLVariables) => {
  const response = await fetchApiInstance(GRAPHQL_URL, {
    method: 'POST',
    cache: 'no-store',
    body: JSON.stringify({
      query: GET_URI_SEO,
      variables
    })
  });

  if (!response.ok) {
    throw new Error(`HTTP error! Status: ${response.status}`);
  }

  const data = await response.json();

  if (data.errors) {
    throw new Error(data.errors[0].message);
  }

  return data.data.nodeByUri.seo;
};
