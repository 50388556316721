import {
  BLOG_CATEGORY_CONTENT_FRAGMENT,
  BLOG_POST_CONTENT_FRAGMENT,
  CART_FIELDS_FRAGMENT,
  CUSTOMER_FRAGMENT,
  MEDIA_ITEM_FIELDS_FRAGMENT,
  ORDER_FIELDS_FRAGMENT,
  PAGE_INFO_CONTENT_FRAGMENT,
  PRODUCT_CATEGORY_FIELDS_FRAGMENT,
  PRODUCT_FAQ_FIELDS_FRAGMENT,
  PRODUCT_FIELDS_FRAGMENT,
  PRODUCT_RELATED_PRODUCTS_FRAGMENT,
  PRODUCT_REVIEWS_FRAGMENT,
  PRODUCT_VARIATION_FIELDS_FRAGMENT,
  SEO_FRAGMENT
} from './fragments';

export const GET_PRODUCTS_QUERY = /* GraphQL */ `
  query GetProducts($first: Int! = 50, $where: RootQueryToProductUnionConnectionWhereArgs) {
    products(first: $first, where: $where) {
      pageInfo {
        ...PageInfoContent
      }
      edges {
        cursor
        node {
          ...ProductFields
          image {
            ...MediaItemFields
          }
          ...ProductVariationFields
        }
      }
    }
  }
  ${PRODUCT_FIELDS_FRAGMENT}
  ${MEDIA_ITEM_FIELDS_FRAGMENT}
  ${PRODUCT_VARIATION_FIELDS_FRAGMENT}
  ${PAGE_INFO_CONTENT_FRAGMENT}
`;

export const GET_PRODUCT_QUERY = /* GraphQL */ `
  query GetProductById(
    $id: ID!
    $idType: ProductIdTypeEnum! = SLUG
    $withReviews: Boolean = false
    $withRelatedProducts: Boolean = false
    $withFAQ: Boolean = false
  ) {
    product(id: $id, idType: $idType) {
      description
      shortDescription
      image {
        ...MediaItemFields
      }
      galleryImages {
        nodes {
          ...MediaItemFields
        }
      }
      seo {
        ...SEO
      }
      productCategories {
        nodes {
          ...ProductCategoryFields
        }
      }
      ...ProductFields
      ...ProductVariationFields
      ...ProductReviews @include(if: $withReviews)
      ...RelatedProducts @include(if: $withRelatedProducts)
      ...ProductFAQFields @include(if: $withFAQ)
    }
  }
  ${PRODUCT_FIELDS_FRAGMENT}
  ${PRODUCT_VARIATION_FIELDS_FRAGMENT}
  ${PRODUCT_REVIEWS_FRAGMENT}
  ${MEDIA_ITEM_FIELDS_FRAGMENT}
  ${SEO_FRAGMENT}
  ${PRODUCT_CATEGORY_FIELDS_FRAGMENT}
  ${PRODUCT_RELATED_PRODUCTS_FRAGMENT}
  ${PRODUCT_FAQ_FIELDS_FRAGMENT}
`;

export const GET_CART_QUERY = /* GraphQL */ `
  query Cart {
    cart {
      ...CartFields
    }
  }
  ${CART_FIELDS_FRAGMENT}
`;

export const GET_MENU_QUERY = /* GraphQL */ `
  query GetMenu($id: ID!, $idType: MenuNodeIdTypeEnum = NAME) {
    menu(id: $id, idType: $idType) {
      id
      name
      menuItems {
        nodes {
          id
          label
          url
          path
          title
          parentId
          title
        }
      }
    }
  }
`;

export const GET_PRODUCT_CATEGORIES_QUERY = /* GraphQL */ `
  query GetCategories(
    $where: RootQueryToProductCategoryConnectionWhereArgs
    $withLastModifiedProduct: Boolean = false
  ) {
    productCategories(where: $where) {
      nodes {
        id
        name
        slug
        uri
        products(first: 1, where: { orderby: { field: MODIFIED } })
          @include(if: $withLastModifiedProduct) {
          nodes {
            modified
            modifiedGmt
          }
        }
      }
    }
  }
`;

export const GET_PRODUCT_CATEGORY_QUERY = /* GraphQL */ `
  query GetCategory($id: ID!, $idType: ProductCategoryIdType! = URI, $withSEO: Boolean = false) {
    productCategory(id: $id, idType: $idType) {
      id
      databaseId
      name
      slug
      description
      uri
      seo @include(if: $withSEO) {
        ...SEO
      }
    }
  }
  ${SEO_FRAGMENT}
`;

export const GET_PAGE = /* GraphQL */ `
  query GetPage($where: RootQueryToPageConnectionWhereArgs) {
    pages(where: $where) {
      nodes {
        id
        title
        content
        slug
      }
    }
  }
`;

export const GET_CUSTOMER_QUERY = /* GraphQL */ `
  query GetCustomer {
    customer {
      ...CustomerFields
    }
  }
  ${CUSTOMER_FRAGMENT}
`;

export const GET_ACCOUNT_ORDERS = /* GraphQL */ `
  query GetAccountOrders {
    orders {
      nodes {
        ...OrderFields
      }
    }
  }
  ${ORDER_FIELDS_FRAGMENT}
`;

export const GET_ACCOUNT_ORDER_IDS = /* GraphQL */ `
  query GetAccountOrderIds {
    orders {
      nodes {
        databaseId
      }
    }
  }
`;

export const GET_ACCOUNT_TICKETS = /* GraphQL */ `
  query GetAccountTickets {
    tickets {
      nodes {
        id
        databaseId
        date
        dateGmt
        comments {
          nodes {
            content
            date
            dateGmt
          }
        }
        ticketStatus
        ticketType
      }
    }
  }
`;

export const GET_ACCOUNT_TICKET = /* GraphQL */ `
  query GetTicket($id: Int!) {
    ticket(where: { id: $id }) {
      node {
        id
        databaseId
        date
        dateGmt
        comments {
          nodes {
            id
            content
            date
            dateGmt
            author {
              node {
                id
                databaseId
                name
              }
            }
          }
        }
        ticketStatus
        ticketType
      }
    }
  }
`;

export const GET_COUNTRIES_QUERY = /* GraphQL */ `
  query Countries {
    countries: countriesWithCode {
      code
      name
    }
  }
`;

export const GET_COUNTRY_STATES_QUERY = /* GraphQL */ `
  query CountryStates($country: CountriesEnum!) {
    countryStates(country: $country) {
      code
      name
    }
  }
`;

export const GET_CHECKOUT_FIELDS_QUERY = /* GraphQL */ `
  query Checkout {
    cart(recalculateTotals: true) {
      ...CartFields
      chosenShippingMethods
      chosenPaymentMethod
    }
    customer {
      calculatedShipping
      hasCalculatedShipping
      ...CustomerFields
    }
    countries: countriesWithCode {
      code
      name
    }
    paymentGateways {
      nodes {
        description
        icon
        id
        title
        instructions
        fee
      }
    }
  }
  ${CART_FIELDS_FRAGMENT}
  ${CUSTOMER_FRAGMENT}
`;

export const GET_CART_FIELDS_QUERY = /* GraphQL */ `
  query CartFields {
    cart(recalculateTotals: true) {
      ...CartFields
      chosenShippingMethods
      chosenPaymentMethod
    }
  }
  ${CART_FIELDS_FRAGMENT}
`;

export const GET_POST_QUERY = /* GraphQL */ `
  query Post($id: ID!, $idType: PostIdType = URI) {
    post(id: $id, idType: $idType) {
      ...BlogPostContent
      content
      seo {
        ...SEO
      }
    }
  }
  ${BLOG_POST_CONTENT_FRAGMENT}
  ${SEO_FRAGMENT}
`;
export const GET_POSTS_QUERY = /* GraphQL */ `
  query Posts(
    $where: RootQueryToPostConnectionWhereArgs
    $first: Int
    $last: Int
    $before: String
    $after: String
  ) {
    posts(where: $where, first: $first, last: $last, before: $before, after: $after) {
      pageInfo {
        ...PageInfoContent
      }
      edges {
        node {
          categories {
            nodes {
              ...BlogCategoryContent
            }
          }
          ...BlogPostContent
        }
      }
    }
  }
  ${BLOG_CATEGORY_CONTENT_FRAGMENT}
  ${BLOG_POST_CONTENT_FRAGMENT}
  ${PAGE_INFO_CONTENT_FRAGMENT}
`;

export const GET_BLOG_CATEGORIES_QUERY = /* GraphQL */ `
  query BlogCategories(
    $where: RootQueryToCategoryConnectionWhereArgs
    $first: Int
    $last: Int
    $before: String
    $after: String
    $withPosts: Boolean = false
    $postsWhere: CategoryToPostConnectionWhereArgs
    $postsFirst: Int
    $postsLast: Int
    $postsBefore: String
    $postsAfter: String
  ) {
    categories(where: $where, first: $first, last: $last, before: $before, after: $after) {
      pageInfo {
        ...PageInfoContent
      }
      edges {
        node {
          id
          name
          slug
          uri
          description
          posts(
            where: $postsWhere
            first: $postsFirst
            last: $postsLast
            before: $postsBefore
            after: $postsAfter
          ) @include(if: $withPosts) {
            pageInfo {
              ...PageInfoContent
            }
            edges {
              node {
                ...BlogPostContent
              }
            }
          }
        }
      }
    }
  }
  ${BLOG_POST_CONTENT_FRAGMENT}
  ${PAGE_INFO_CONTENT_FRAGMENT}
`;

export const GET_BLOG_CATEGORY_QUERY = /* GraphQL */ `
  query BlogCategory($id: ID!, $idType: CategoryIdType! = URI, $withPosts: Boolean = false) {
    category(id: $id, idType: $idType) {
      id
      name
      slug
      uri
      description
      seo {
        ...SEO
      }
      posts @include(if: $withPosts) {
        pageInfo {
          ...PageInfoContent
        }
        edges {
          node {
            ...BlogPostContent
          }
        }
      }
    }
  }
  ${BLOG_POST_CONTENT_FRAGMENT}
  ${PAGE_INFO_CONTENT_FRAGMENT}
  ${SEO_FRAGMENT}
`;

export const GET_PRODUCT_ATTRIBUTE_FILTERS_QUERY = /* GraphQL */ `
  query Taxonomies {
    brands: allPaBrand {
      nodes {
        id
        count
        name
        taxonomyName
        slug
      }
    }
    activeSubstances: allPaSubstance {
      nodes {
        id
        count
        name
        taxonomyName
        slug
      }
    }
    productForms: allPaProductForm {
      nodes {
        id
        count
        name
        taxonomyName
        slug
      }
    }
  }
`;

export const GET_ALL_BRANDS_QUERY = /* GraphQL */ `
  query Brands($first: Int! = 50, $where: RootQueryToPaBrandConnectionWhereArgs) {
    brands: allPaBrand(first: $first, where: $where) {
      nodes {
        id
        count
        name
        taxonomyName
        slug
      }
    }
  }
`;

export const GET_BRAND_QUERY = /* GraphQL */ `
  query Brand($id: ID!, $idType: PaBrandIdType = SLUG) {
    brand: paBrand(id: $id, idType: $idType) {
      id
      name
      slug
      description
      seo {
        ...SEO
      }
    }
  }
  ${SEO_FRAGMENT}
`;

export const GET_SHIPPING_CLASSES_QUERY = /* GraphQL */ `
  query ShippingClasses {
    shippingClasses {
      nodes {
        name
        slug
      }
    }
  }
`;

export const GET_PAGE_HEROES_QUERY = /* GraphQL */ `
  query PageHeroes($where: RootQueryToPageHeroConnectionWhereArgs) {
    pageHeroes(where: $where) {
      nodes {
        title
        pageHeroFields {
          uri
          description
          backgroundColor
          textColor
          links {
            link {
              url
              title
            }
            image {
              node {
                sourceUrl
              }
            }
          }
        }
      }
    }
  }
`;

export const GET_URI_SEO = /* GraphQL */ `
  query URISEO($uri: String!) {
    nodeByUri(uri: $uri) {
      ... on NodeWithRankMathSeo {
        seo {
          breadcrumbs {
            url
            isHidden
          }
          breadcrumbTitle
          canonicalUrl
          description
          robots
          title
        }
      }
    }
  }
`;
