import { CUSTOMER_FRAGMENT, CART_FIELDS_FRAGMENT } from './fragments';

export const ADD_TO_CART_MUTATION = /* GraphQL */ `
  mutation AddToCart($productId: Int!, $quantity: Int! = 1) {
    addToCart(input: { productId: $productId, quantity: $quantity }) {
      cart {
        ...CartFields
      }
    }
  }
  ${CART_FIELDS_FRAGMENT}
`;

export const SIGN_IN_MUTATION = /* GraphQL */ `
  mutation Login($username: String!, $password: String!, $gRecaptchaToken: String) {
    login(input: { username: $username, password: $password, gRecaptchaToken: $gRecaptchaToken }) {
      authToken
      refreshToken
      sessionToken
      customer {
        ...CustomerFields
      }
      user {
        wooSessionToken
        avatar {
          default
          url
          size
          width
        }
      }
    }
  }
  ${CUSTOMER_FRAGMENT}
`;

export const UPDATE_CART_QUANTITY_MUTATION = /* GraphQL */ `
  mutation UpdateCartItemQuantities($items: [CartItemQuantityInput]) {
    updateItemQuantities(input: { items: $items }) {
      cart {
        ...CartFields
      }
    }
  }
  ${CART_FIELDS_FRAGMENT}
`;

export const REMOVE_CART_PRODUCT_MUTATION = /* GraphQL */ `
  mutation RemoveItemsFromCart($keys: [ID], $all: Boolean) {
    removeItemsFromCart(input: { keys: $keys, all: $all }) {
      cart {
        ...CartFields
      }
      cartItems {
        ...CartItemContent
      }
    }
  }
`;

export const REFRESH_TOKEN_MUTATION = /* GraphQL */ `
  mutation RefreshAuthToken($refreshToken: String!) {
    refreshJwtAuthToken(input: { jwtRefreshToken: $refreshToken }) {
      authToken
    }
  }
`;

export const REGISTER_CUSTOMER_MUTATION = /* GraphQL */ `
  mutation Register(
    $email: String!
    $password: String!
    $firstName: String!
    $gRecaptchaToken: String
  ) {
    registerCustomer(
      input: {
        email: $email
        password: $password
        firstName: $firstName
        gRecaptchaToken: $gRecaptchaToken
      }
    ) {
      authToken
      refreshToken
      customer {
        ...CustomerFields
      }
      user: viewer {
        wooSessionToken
        avatar {
          default
          url
          size
          width
        }
      }
    }
  }
  ${CUSTOMER_FRAGMENT}
`;

export const UPDATE_CUSTOMER_ADDRESS = /* GraphQL */ `
  mutation UpdateCustomerAddress(
    $firstName: String
    $lastName: String
    $postcode: String
    $state: String
    $city: String
    $country: CountriesEnum
    $address1: String
  ) {
    updateCustomer(
      input: {
        shipping: {
          firstName: $firstName
          lastName: $lastName
          postcode: $postcode
          country: $country
          state: $state
          city: $city
          address1: $address1
        }
        billing: {
          firstName: $firstName
          lastName: $lastName
          postcode: $postcode
          country: $country
          state: $state
          city: $city
          address1: $address1
        }
      }
    ) {
      customer {
        ...CustomerFields
      }
    }
  }
  ${CUSTOMER_FRAGMENT}
`;

export const SET_SHIPPING_METHOD = /* GraphQL */ `
  mutation SetShippingMethod($input: UpdateShippingMethodInput!) {
    updateShippingMethod(input: $input) {
      cart {
        shippingTotal
        total
      }
    }
  }
`;

export const SET_PAYMENT_METHOD = /* GraphQL */ `
  mutation SetPaymentMethod($input: UpdatePaymentMethodInput!) {
    updatePaymentMethod(input: $input) {
      clientMutationId
    }
  }
`;

export const CHECKOUT_MUTATION = /* GraphQL */ `
  mutation Checkout($input: CheckoutInput!) {
    checkout(input: $input) {
      result
      order {
        id
        databaseId
        orderNumber
        orderVersion
        status
        thankyouInstructions
      }
    }
  }
`;

export const CREATE_TICKET_MUTATION = /* GraphQL */ `
  mutation createTicket($input: CreateTicketInput!) {
    createTicket(input: $input) {
      ticketId
    }
  }
`;

export const REPLY_TICKET_MUTATION = /* GraphQL */ `
  mutation replyTicket($input: ReplyTicketInput!) {
    replyTicket(input: $input) {
      ticket {
        id
      }
    }
  }
`;

export const APPLY_COUPON_MUTATION = /* GraphQL */ `
  mutation applyTicket($input: ApplyCouponInput!) {
    applyCoupon(input: $input) {
      applied {
        code
        description
        discountAmount
      }
    }
  }
`;

export const WRITE_REVIEW_MUTATION = /* GraphQL */ `
  mutation WriteReview($input: WriteReviewInput!) {
    writeReview(input: $input) {
      rating
      review {
        agent
        author {
          node {
            name
            email
          }
        }
        karma
        status
        type
      }
    }
  }
`;

export const LOGOUT_MUTATION = /* GraphQL */ `
  mutation Logout {
    logout(input: {}) {
      status
    }
  }
`;

export const SAVE_ADDITIONAL_ADDRESS_MUTATION = /* GraphQL */ `
  mutation SaveAdditionalAddress(
    $id: String
    $addressTitle: String
    $address: CustomerAddressInput!
  ) {
    saveAdditionalAddress(input: { id: $id, addressTitle: $addressTitle, address: $address }) {
      additionalAddress {
        id
        addressTitle
        address {
          firstName
          lastName
          address1
          country
          state
          city
          postcode
        }
      }
    }
  }
`;

export const SEND_PASSWORD_RESET_MUTATION = /* GraphQL */ `
  mutation sendPasswordReset($username: String!) {
    sendPasswordResetEmail(input: { username: $username }) {
      success
    }
  }
`;
